import { Icon } from '@components/common/generic-icon';
import { Badge } from '@components/common/badge';
import React from 'react';
import { mq } from '@styles/breakpoints';
import { arrowAnimation } from '@components/common/hero/heroAnimations';
import { Theme, ThemeProps } from '@theme/base';
import styled from '@emotion/styled';
import { ImageDestination } from '@model/iceberg';
import { withTheme } from '@emotion/react';
import { TripTypes } from '@model/contentful';

export interface BottomProps {
  chevron: boolean;
  destination: ImageDestination;
  theme?: Theme;
  tripType?: TripTypes;
}
const FooterContainer = styled.div<any>(() => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  left: 0
}));

const FooterRelative = styled.div<any>(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  [mq.small]: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const IconBadgeContainer = styled.div<any>(({ theme }: ThemeProps) => ({
  animation: `${arrowAnimation('1rem')} 2s infinite`,
  margin: theme.custom.spacing.medium,
  marginTop: theme.custom.spacing.xSmall,
  [mq.small]: {
    animation: `${arrowAnimation('2rem')} 2s infinite`,
    margin: theme.custom.spacing.xSmall,
    position: 'absolute',
    bottom: '2.2rem'
  }
}));

const DestinationBadgeContainer: any = styled.div<any>({
  position: 'relative',
  [mq.small]: {
    position: 'absolute',
    bottom: 0,
    right: 0
  }
});

const LocationIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2rem',
  color: theme.custom.states.link.default
}));

export const BottomComponent = ({ chevron, theme, destination }: BottomProps) => {
  return (
    <FooterContainer>
      <FooterRelative>
        {chevron && (
          <IconBadgeContainer>
            <Icon name={'mhi-chevron-down'} css={{ fontSize: '2rem', color: theme!.custom.colors.white }} />
          </IconBadgeContainer>
        )}
        {destination.name && (
          <DestinationBadgeContainer className={'badgeContainer'}>
            <Badge
              label={`${destination.name}, ${destination.parent}`}
              containerStyle={{ backgroundColor: theme!.custom.colors.white, margin: 20 }}
              tooltip={'hero__button--tooltip'}
              renderIcon={<LocationIcon name={'mhi-map-pin'} />}
            />
          </DestinationBadgeContainer>
        )}
      </FooterRelative>
    </FooterContainer>
  );
};

export const Bottom = withTheme(BottomComponent);
