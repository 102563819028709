import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps, SelectableThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { AppBar, Tabs, Tab } from '@mui/material';
import { Icon } from '@components/common/generic-icon';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { useDispatch, useSelector } from 'react-redux';
import { getTabIndex, setTabIndex } from '@state/search';

/* ***************** *
 *       Types       *
 * ***************** */
export enum TabSearchVariant {
  HOMEPAGE = 'HOMEPAGE',
  NAVBAR = 'NAVBAR'
}

export interface TabSearchProps {
  theme?: Theme;
  testId?: string;
  children: Array<JSX.Element> | JSX.Element;
  tabLabels: Array<TabLabel>;
  variant?: TabSearchVariant;
  fullWidth?: boolean;
}

interface TabLabel {
  label: string;
  icon: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const TabSearchContainer = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',

  ['.MuiTabs-root']: {
    minHeight: 44
  },

  ['.MuiTabs-scrollable']: {
    backgroundColor: theme.custom.colors.white,
    borderRight: `1px solid ${theme.custom.colors.group10.light}`,
    borderTop: `1px solid ${theme.custom.colors.group10.light}`,

    [mq.small]: {
      backgroundColor: 'transparent',
      borderRadius: `4px 4px 0 0`,
      borderRight: 'none',
      borderTop: 'none'
    }
  },

  ['.MuiTab-root']: {
    alignItems: 'center',
    backgroundColor: theme.custom.colors.white,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.6rem',
    fontWeight: 500,
    opacity: 1,
    padding: theme.custom.spacing.small,
    textTransform: 'none',
    transition: `color ${theme.custom.transitions.default}`,

    [mq.small]: {
      borderTop: `1px solid ${theme.custom.colors.group10.light}`
    },

    [mq.medium]: {
      minWidth: 200,
      padding: `${theme.custom.spacing.small}px ${theme.custom.spacing.medium}px`
    },

    ['&:first-of-type']: {
      [mq.small]: {
        borderRadius: `4px 0 0 0`,
        borderLeft: `1px solid ${theme.custom.colors.group10.light}`
      }
    },

    ['&:last-of-type']: {
      [mq.small]: {
        borderRadius: `0 4px 0 0`,
        borderRight: `1px solid ${theme.custom.colors.group10.light}`
      }
    }
  },

  ['.MuiAppBar-root']: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  ['.MuiTab-labelIcon']: {
    ['> i']: {
      fontSize: '1.4rem',
      marginBottom: 0,
      marginRight: theme.custom.spacing.xSmall,
      [mq.small]: {
        fontSize: '2rem',
        marginRight: theme.custom.spacing.small
      }
    }
  },

  ['.MuiTabs-indicator']: {
    display: 'none'
  }
}));

const HomepageTabSearchContainer = styled(TabSearchContainer)(({ theme }: ThemeProps) => ({
  [mq.small]: {
    marginTop: theme.custom.spacing.large
  },

  ['.MuiTab-root']: {
    fontSize: '2rem',
    minHeight: 44
  },

  ['button']: {
    color: theme.custom.colors.group4.base,
    ...(theme.custom.typography.h6 as any),
    [mq.small]: {
      ...(theme.custom.typography.h5 as any),
      fontWeight: 500
    },
    ['> i']: {
      color: theme.custom.colors.group4.base,
      paddingBottom: 1
    }
  },

  ['.Mui-selected']: {
    ['button, > i']: {
      color: theme.custom.colors.group1.base
    },
    ['> i']: {
      [':after']: {
        backgroundColor: theme.custom.colors.group1.base,
        border: `2px solid ${theme.custom.colors.group1.base}`,
        borderRadius: 2,
        bottom: 0,
        content: '""',
        left: 10,
        position: 'absolute',
        right: 10
      }
    }
  },

  ['.MuiSvgIcon-root']: {
    fontSize: '2rem',
    background: theme.custom.colors.white,
    borderRadius: '50%'
  },

  [mq.medium]: {
    marginTop: '-5vh'
  }
}));

interface NavBarTabSearchContainerProps extends ThemeProps {
  fullWidth: boolean;
}
const NavBarTabSearchContainer: any = styled(TabSearchContainer)(
  ({ theme, fullWidth }: NavBarTabSearchContainerProps) => ({
    ['.MuiTabs-root']: {
      justifyContent: 'center',

      ['& > div']: {
        width: '100%',
        padding: fullWidth ? '0 3%' : `0 ${theme.custom.spacing.medium}px`,
        maxWidth: fullWidth ? '100%' : theme.custom.contentSizes.maxContentWidth
      }
    },

    ['.MuiTab-root']: {
      minHeight: 44,
      backgroundColor: theme.custom.colors.white,
      border: `1px solid ${theme.custom.colors.group10.lighter}`,
      transition: `background-color ${theme.custom.transitions.default}`,

      ['&.Mui-selected']: {
        backgroundColor: theme.custom.colors.group10.lighter,
        color: theme.custom.colors.group4.base
      }
    },

    ['.MuiAppBar-root']: {
      backgroundColor: theme.custom.colors.white,
      zIndex: ZIndex.CONTENT
    },

    ['button, > i']: {
      color: theme.custom.colors.group4.base,
      ...(theme.custom.typography.paragraph as any)
    },

    ['.Mui-selected']: {
      ['button, > i']: {
        color: theme.custom.colors.group4.base
      }
    }
  })
);

const TabContentContainer = styled.div({
  position: 'relative'
});

interface TabContentItemProps extends SelectableThemeProps {
  isHomepage: boolean;
}
const TabContentItem: any = styled.div(({ theme, isSelected, isHomepage }: TabContentItemProps) => ({
  width: '100%',
  ...(isHomepage && {
    display: isSelected ? 'block' : 'none',
    transition: `opacity ${theme.custom.transitions.smooth}, max-height ${theme.custom.transitions.smooth}`,
    opacity: isSelected ? 1 : 0,
    backgroundColor: theme.custom.colors.white,
    border: `1px solid ${theme.custom.colors.group10.light}`,
    padding: theme.custom.spacing.medium,
    pointerEvents: isSelected ? 'all' : 'none',
    [mq.small]: {
      borderRadius: '0 4px 4px 4px',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0
    }
  }),

  ...(!isHomepage && {
    display: isSelected ? 'flex' : 'none',
    justifyContent: 'center',
    padding: '10px 0'
  })
}));

interface TabContentInnerProps extends ThemeProps {
  isHomepage: boolean;
  fullWidth: boolean;
}
const TabContentInner: any = styled.div(({ theme, isHomepage, fullWidth }: TabContentInnerProps) => ({
  width: '100%',
  ...(!isHomepage && {
    padding: fullWidth ? '0 3%' : `0 ${theme.custom.spacing.medium}px`,
    maxWidth: fullWidth ? '100%' : theme.custom.contentSizes.maxContentWidth
  })
}));

/* ************************ *
 *    TabSearchComponent    *
 * ************************ */

export const TabSearchComponent = (props: TabSearchProps) => {
  const t: any = useI18NextContext();
  const { children, tabLabels, variant, fullWidth } = props;
  const isHomepage = variant === TabSearchVariant.HOMEPAGE;
  const dispatch = useDispatch();
  const tabIndex: number = useSelector(getTabIndex);

  /* *** LOGIC *** */
  const getChildrenArray = () => (Array.isArray(children) ? children : [children]) || [];

  function handleChange(event: any, newValue: number) {
    event.preventDefault();
    dispatch(setTabIndex(newValue));
  }

  /* *** RENDERERS *** */
  const renderTabsContent = () => {
    return getChildrenArray().map((child: JSX.Element, index: number) => (
      <TabContentItem
        data-testid={index === tabIndex ? TestId.tabSearch.item.selected : TestId.tabSearch.item.notSelected}
        key={index}
        isSelected={index === tabIndex}
        isHomepage={isHomepage}
      >
        <TabContentInner isHomepage={isHomepage} fullWidth={fullWidth}>
          {child}
        </TabContentInner>
      </TabContentItem>
    ));
  };

  const renderTabs = () =>
    tabLabels.map(({ label, icon }: TabLabel, index: number) => (
      <Tab key={`${label}-${index}`} icon={<Icon name={icon} />} label={t(label)} />
    ));

  const Container = isHomepage ? HomepageTabSearchContainer : NavBarTabSearchContainer;

  return (
    <Container data-testid={props.testId || TestId.tabSearch.main} fullWidth={fullWidth}>
      <>
        <AppBar position="static" color="default">
          <Tabs value={tabIndex} onChange={handleChange} variant={'scrollable'}>
            {renderTabs()}
          </Tabs>
        </AppBar>
        <TabContentContainer>{renderTabsContent()}</TabContentContainer>
      </>
    </Container>
  );
};

TabSearchComponent.defaultProps = {
  variant: TabSearchVariant.HOMEPAGE
};

export const TabSearch = withTheme(TabSearchComponent);
