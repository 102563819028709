import { keyframes } from '@emotion/css';

export const arrowAnimation = (distance: string) => keyframes`
  0% {
    opacity: 0.4;
    transform: translateY(-${distance});
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(${distance});
  }
`;
