import React from 'react';
import { Bottom, BottomProps } from './Bottom';
import { useRouter, NextRouter } from 'next/router';
import { TripTypes } from '@model/contentful';

export const BottomContainer = (props: BottomProps) => {
  const router: NextRouter = useRouter();
  const currentTripType: TripTypes = router?.query?.tripType as TripTypes;

  return <Bottom {...props} tripType={props.tripType || currentTripType || TripTypes.HOLIDAYS} />;
};
