import React from 'react';
import { useAsync } from 'react-use';
import { map } from 'lodash';
import { SectionTitle, SectionTitleSizes } from '@components/common/section-title';
import { Theme } from '@theme/base';
import { withTheme } from '@emotion/react';
import { HotelContentApi } from '@model/iceberg/service/hotel-content/hotel-content-api';
import { RecentlyViewedItem, RecentlyViewedType } from '@state/recently-viewed';
import { FITRecentlyViewedCard } from './card/FITRecentlyViewedCard';
import { ToursRecentlyViewedCard } from './card/ToursRecentlyViewedCard';
import { TestId } from '@components/test-ids';
import { GridCarousel } from '@components/common/grid-carousel';
import Grid from '@mui/material/Grid';

export interface RecentlyViewedProps {
  theme?: Theme;
  title: string;
  recentlyViewed: Array<RecentlyViewedItem>;
}

export const RecentlyViewedComponent = ({ theme, title, recentlyViewed }: RecentlyViewedProps) => {
  const recentlyViewedFit = recentlyViewed.filter(({ type }) => type === RecentlyViewedType.FIT);
  const hotelTileResponse = useAsync(async () => await new HotelContentApi().tiles(map(recentlyViewedFit, 'id')), []);

  if (
    recentlyViewedFit.length > 0 &&
    (hotelTileResponse.loading || !hotelTileResponse.value || !hotelTileResponse.value.data)
  )
    return null;

  return (
    <div data-testid={TestId.recentlyViewed.container}>
      {title && <SectionTitle heading={title} size={SectionTitleSizes.LARGE} />}
      <GridCarousel container spacing={theme!.custom.gridSpacing.default}>
        {recentlyViewed.map(({ id, type, tripType }: RecentlyViewedItem) => (
          <Grid key={`${id}-${tripType}`} item xs={12} sm={6} md={3}>
            {type === RecentlyViewedType.FIT && (
              <FITRecentlyViewedCard
                hotel={hotelTileResponse?.value?.data.find(({ place: { hotel } }) => hotel?.path === id)}
              />
            )}
            {type === RecentlyViewedType.TOURS && <ToursRecentlyViewedCard id={id} />}
          </Grid>
        ))}
      </GridCarousel>
    </div>
  );
};

export const RecentlyViewed = withTheme(RecentlyViewedComponent);
